import _ from "lodash";
import { environment as defaultEnvironment } from './environment.defaults';

export const environment = _.merge({}, defaultEnvironment, {
  environmentName: 'qa',
  production: false,
  log: false,
  flags: {
    useNewHeader: false
  },
  assetsStorageUrl: 'https://assets-embark-qa.ehr.com',
  invisibleReCaptchaSiteKey: "6LcNyOkaAAAAAN_N1IBE9lqRC_ghp47dsssWWI_q",
  withChallengeReCaptchaSiteKey: "6LdSv-UaAAAAAD2MOpHKrjK8EquuhMzmm1_XzBA3",
  appInsightsConfig: {
    instrumentationKey: '35b5ac27-f2db-4d90-bfdb-d3e0884afcf9',
    environmentName: 'qa'
  },
  appInsightsConfigPro: {
    instrumentationKey: '391c3a5c-3c34-46c3-a369-9ddc70dd8dc4',
    environmentName: 'AzQA',
  },
  registrationApiBaseUrl: 'https://embark-qa-gw.ehr.com/api/Registration/',
  translationApiBaseUrl: 'https://embark-qa-gw.ehr.com/api/Registration/',
  configApiUrl: 'https://embarkpro-qa.ehr.com/std-gateway/api/registrationConfig/',
  loginFriendlyBaseUrl: 'https://signin-embark-qa.ehr.com/',
  kbaBaseUrl: 'https://embark-qa-gw.ehr.com/api/kba/',
  loginEmbarkAppFriendlyBaseUrl: '',
  oneTrustConfig: {
    scriptSource: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    scriptId: '1833c667-beea-48f0-9bee-fad3b1bd9cf1-test',
    defaultLang: '',
    privacyNoticeGuid: 'bbb80b16-980d-443f-94f1-e348aca60056',
    cookieNoticeGuid: 'b8716e4f-acd5-4ffb-9217-eb31a9b3509b',
    termsOfUseGuid: '882e2831-64c6-4ca1-974b-d53591a973fb',
    noticeBundleGuid: 'c20a4882-10af-4ef8-8376-cbffed898dfa',
  },
  pushNotificationsApiBaseUrl: 'https://embarkpro-qa.ehr.com/std-gateway/api/pushnotifications',
  redirectUserProfileEmbark: 'https://embark-qa.ehr.com/user-profile'
});

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.