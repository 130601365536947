import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrowserUtility } from './../utility/browser.utility';
import * as DOMPurify from 'dompurify';
@Injectable({
  providedIn: 'root'
})
export class BrowserContextService {
  possibleLanguageParams: string[] = ['cultureCode', 'ui_locales'];
  possibleClientCountryParams: string[] = ['clientCountry', 'clientCountryId'];
  urlRegex: RegExp = new RegExp(/^(https?):\/\/[^\s$.?#].[^\s]*$/g);
  urlHasParams: boolean = false;
  urlParams: any = null;
  clientCountryCode: string | null;
  cultureCodeKey: string = 'culture-code';
  clientCountryCodeKey: string = 'clientCountry';
  browserUtil: BrowserUtility = new BrowserUtility();

  constructor() {
    const browserUrl = this.urlRegex.test(window.location.href) ? window.location.href : null;
    this.urlHasParams = browserUrl?.indexOf('?') > 0;
    this.urlParams = this.urlHasParams ? this.getParams(browserUrl) : null;
  }

  //#region public methods
  public GetCultureCode(): string {
    let language: string | null = null;
    if (this.urlHasParams) {
      language = this.tryGetValueFromQueryString(this.possibleLanguageParams);
      if (this.isValidCode(language)) {
        if (language.toLocaleLowerCase() == 'zh-hant') language = 'zh-HK';
        else if (language.toLocaleLowerCase() == 'zh-hans') language = 'zh-CN';
        this.browserUtil.saveToSessionStorage(this.cultureCodeKey, language);
        return language;
      }
    }

    if (!language || !this.isValidCode(language)) {
      language = this.getBrowserLangOrDefault();
    }

    this.browserUtil.saveToSessionStorage(this.cultureCodeKey, language);
    return language;
  }
  //#endregion public methods

  //#region Service methods
  private tryGetValueFromQueryString(possibleParams: string[]): string | null {
    if (this.urlParams) {
      for (const param of possibleParams) {
        if (param in this.urlParams) {
          return this.urlParams[param];
        }
      }
    }
    return null;
  }

  private getParams(url: string): any {
    let parser = document.createElement('a');
    parser.href = DOMPurify.sanitize(url);
    let query = parser.search.substring(1);
    let vars = query.split('&');

    let params = {};
    for (const varSet of vars) {
      const pair = varSet.split('=');
      if (pair.length > 1) {
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    }
    return params;
  }

  private isValidCode(code: string): boolean {
    const codeRegEx = new RegExp(/-/g);
    return codeRegEx.test(code);
  }

  private getBrowserLangOrDefault(): string {
    const storedCode = this.browserUtil.getFromSessionStorage(this.cultureCodeKey);
    if (storedCode && this.isValidCode(storedCode)) return storedCode;

    const browserLang = window?.navigator.language;
    if (browserLang) return browserLang;

    return environment.defaultLanguage;
  }
  //#endregion Service methods
}
